<template>
  <div class="autograph-box">
    <div class="autograph-box-content">
      <span class="primary-text">重置</span>
    </div>
    <div class="btn">
      <el-button  plain>取消</el-button>
      <el-button  plain>确认</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "autograph"
  }
</script>

<style lang="scss" scoped>
  .autograph-box {
    height: 200px;
    border: 1px solid #cccccc;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 15px;
    .autograph-box-content {
      background-color: #F0F0F0;
      width: 100%;
      padding: 60px 0;
      position: relative;
      span {
        position: absolute;
        top: 10px;
        right: 15px;
      }
    }
    .btn {
      float: right;
      margin-top: 10px;
    }
  }
</style>
